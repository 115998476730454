<template>
    <ICountUp
        :delay="delay"
        :endVal="endVal"
        :options="options"
        @ready="onReady"
    />
</template>

<script>
    import ICountUp from 'vue-countup-v2';

    export default {
        components: {
            ICountUp
        },
        props: {
            suffix: {
                type: String,
                default: ''
            },
            value: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                delay: 1000,
                endVal: 0,
                instance: null,
                options: {
                    useEasing: true,
                    useGrouping: true,
                    separator: '.',
                    decimal: ','
                }
            };
        },
        methods: {
            updateCounter() {
                if (!this.instance) return;
                this.instance.update(this.value);
            },
            onReady(instance) {
                this.instance = instance;
                this.updateCounter();
            }
        },
        watch: {
            value() {
                this.updateCounter();
            }
        }
    };
</script>

