<template>
    <div class="cards">
        <div class="card">
            <div class="card-body">
                <h1 class="card-title text-success uppercase font-weight-bolder">{{ title }}</h1>
                <p>{{ message }}</p>
                <img v-if="pictureUrl" class="img-fluid" :src="pictureUrl" alt="">
            </div>
        </div>
    </div>
</template>

<style scoped>
main {
    position: absolute;
    width: 100%;
    height: 100%;
}

.cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.card {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.card-body {
    text-align: center;
}
</style>

<script>
import DateCampaignMixin from "./DateCampaignMixin";

export default {
    mixins: [DateCampaignMixin],
    data() {
        return {
            dateField: 'end_campaign',
            title: 'Obrigado!',
            message: 'Campanha encerrada!',
            pictureUrl: null
        }
    },
}
</script>

