import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters([
            '_store_getDarkMode'
        ]),
        borderColor() {
            return this._store_getDarkMode ? '#ccc' : '#666';
        }
    }
}
