<template>
    <nav class="navbar navbar-default navbar-expand-sm">

        <a class="navbar-brand" href="//www.amigosdobem.org" target="_blank">
            <img :src="logoPath" width="30" height="30" alt="">
        </a>

        <div class="navbar-title">
            {{ _store_getAuthSettingsProp('name') }}
        </div>

        <div class="navbar-partner" v-if="logoPicture">
            <img :src="logoPicture" width="30" height="30" alt="">
        </div>

        <div class="navbar-menu">
            <button class="btn btn-link" ref="buttonMenu" type="button" @click="showMenu = !showMenu">
                <font-awesome-icon icon="ellipsis-v"/>
            </button>

            <div class="dropdown-menu" v-show="showMenu">
                <a class="dropdown-item d-none d-lg-block" href="#" v-toggle-full-screen>
                    <font-awesome-icon icon="expand-alt" />
                    Tela Cheia
                </a>
                <a class="dropdown-item" href="#" @click="refresh">
                    <font-awesome-icon icon="redo-alt" />
                    Atualizar
                </a>
                <a v-if="darkModeItem" class="dropdown-item" href="#" @click="toggleDarkMode">
                    <template v-if="darkMode">
                        <font-awesome-icon icon="moon" /> Tema escuro
                    </template>
                    <template v-else>
                        <font-awesome-icon icon="sun" /> Tema claro
                    </template>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item no-icon" href="#" @click="signOut">
                    Sair
                </a>
            </div>
        </div>
    </nav>
</template>

<style scoped>
nav.navbar {
    justify-content: space-between;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12);
}

.navbar .navbar-brand {
    padding: 2px 0;
    margin: 0;
}

nav.navbar .navbar-brand img,
nav.navbar .navbar-partner img {
    width: auto;
    height: 100%;
    max-height: 50px;
}

nav.navbar .navbar-title {
    display: none;
    font-weight: 500;
    color: #666;
}

nav.navbar .navbar-menu button {
    font-size: 1.5rem;
    color: #666;
}

nav.navbar .navbar-menu button:hover {
    color: #444;
}

nav.navbar .navbar-menu .dropdown-menu {
    display: block;
    right: 10px;
    left: auto;
    top: 70%;
}

nav.navbar .dropdown-item.no-icon {
    padding-left: 55px;
}

nav.navbar .dropdown-item svg {
   padding-right: 10px;
}

@media (min-width: 800px) {
    nav.navbar .navbar-brand img,
    nav.navbar .navbar-partner img {
        max-height: 60px;
    }

    nav.navbar .navbar-title {
        display: block;
        font-size: 2rem;
    }

    nav.navbar .navbar-menu button {
        font-size: 1.7rem;
    }
}

@media (min-width: 1400px) {
    nav.navbar .navbar-brand img,
    nav.navbar .navbar-partner img {
        max-height: 70px;
    }

    nav.navbar .navbar-title {
        font-size: 2.2rem;
    }

    nav.navbar .navbar-menu button {
        font-size: 2rem;
    }
}
</style>

<script>
import '../../directives/toggleFullScreen';
import {mapGetters} from "vuex";
import CommonMixin from "../../mixins/CommonMixin";

export default {
    mixins: [CommonMixin],
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        darkModeItem: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showMenu: false,
            logoPicture: null,
            listener: null,
            logoPath: require('../../assets/img/logos/logo-color.svg')
        }
    },
    methods: {
        getLogoPicture() {
            let logoPicture = this._store_getAuthSettingsProp('logo');

            if (!logoPicture) return;

            this._common_getUserAsset(logoPicture)
                .getDownloadURL()
                .then(url => this.logoPicture = url);
        },
        refresh() {
            location.reload();
        },
        toggleDarkMode() {
            this.$emit('toggle-dark-mode');
        },
        signOut() {
            this.$router.push({
                name: 'sign-out'
            });
        },
        setClickOutsideEvent() {
            this.listener = (e) => {
                if (!this.$refs.buttonMenu.contains(e.target)) {
                    this.showMenu = false;
                }
            };

            document.addEventListener('click', this.listener);
        },
        unsetClickOutsideEvent() {
            if (!this.listener) return;
            document.removeEventListener('click', this.listener);
        }
    },
    computed: {
        ...mapGetters([
            '_store_getAuthSettingsProp'
        ])
    },
    mounted() {
        this.setClickOutsideEvent();
    },
    destroyed() {
        this.unsetClickOutsideEvent();
    },
    created() {
        this.getLogoPicture();
    }
}
</script>

