import Vue from "vue";

Vue.directive('toggleFullScreen', {
    // When the bound element is inserted into the DOM...
    inserted: function (el) {
        const isFullScreen = function() {
            return typeof window.fullScreen !== 'undefined'
                ? window.fullScreen
                : (!window.screenTop && !window.screenY);
        };

        const setFullscreen = () => {
            let elem = document.documentElement;

            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) { /* Firefox */
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE/Edge */
                elem.msRequestFullscreen();
            }
        };

        const closeFullscreen = () => {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
            }
        };

        const toggle = () => {
            if (isFullScreen()) {
                closeFullscreen();
            } else {
                setFullscreen();
            }
        };

        el.addEventListener('click', toggle);
    }
});
