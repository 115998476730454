export var
    Vibrant = [
        '#36A2EB',
        '#FF3784',
        '#4BC0C0',
        '#F77825',
        '#9966FF',
        '#00A8C6',
        '#379F7A',
        '#CC2738',
        '#8B628A',
        '#8FBE00',
        '#606060',
        '#075f99',
        '#a70d48',
        '#109d9d',
        '#923f09',
        '#3b1091',
        '#0e8fa5',
        '#0b724d',
        '#830d19',
        '#7e0d7b',
        '#617e09',
        '#533f3f'
    ];
